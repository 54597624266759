import * as React from 'react';

import { Home, Layout } from '../components';

const IndexPage = () => {
  return (
    <>
      <Layout.NavMenu />
      <Home.HeroImage />
      <Home.CoverageMap />
      <Home.SelectState />
      <Home.PropertyTaxServices />
      <Home.WhyChooseUs />
      <Home.OurCompany />
      <Home.Contact />
      <Layout.Footer />
    </>
  );
};

export default IndexPage;

export const Head = () => <Layout.PageHead />;
